import { useCallback, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
// import { useCart } from '../../context/useCart';
import { checkoutHandler } from "../../utils/APIUtils";

export default function CheckoutForm({ checkoutObj }) {
  // const { checkoutMutate } = useCart();
  const promoterStripeId = checkoutObj.promoterStripeId;

  console.log("stripeAccount in CheckoutForm =>", promoterStripeId);
  delete checkoutObj.promoterStripeId;
  console.log("checkoutObj in CheckoutForm =>", checkoutObj);
  console.log("stripe => ", process.env.REACT_APP_STRIPE_API_KEY);
  console.log(typeof process.env.REACT_APP_STRIPE_API_KEY);

  const stripePromise = useMemo(
    () =>
      loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
        stripeAccount: promoterStripeId,
      }),
    [promoterStripeId]
  );

  console.log("stripePromise in CheckoutForm =>", stripePromise);

  const fetchClientSecret = useCallback(
    // create a checkout session
    async () => {
      try {
        const clientSecret = await checkoutHandler(checkoutObj);
        console.log("Resolved client secret:", clientSecret);
        if (!clientSecret || typeof clientSecret !== "string") {
          throw new Error("Invalid client secret");
        }
        return clientSecret;
      } catch (error) {
        console.error("fetchClientSecret error:", error);
        throw error;
      }
    },
    [checkoutObj]
  );

  console.log("fetchClientSecret in CheckoutForm =>", fetchClientSecret);

  const options = {
    fetchClientSecret,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
