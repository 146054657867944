import styles from "./Tickets.module.scss";
import PerformanceCard from "./PerformanceCard/PerformanceCard";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getUserTickets, getReceivingTickets } from "../../../utils/APIUtils";
import {
  createTicketTransfer,
  acceptTicketTransfer,
} from "../../../utils/APIUtils";
import ReceivingTickets from "./ReceivingTickets/ReceivingTickets";
import { useNavigate } from "react-router-dom";

export default function Tickets() {
  const navigate = useNavigate();
  // Queries
  const {
    status: userTicketsStatus,
    data: userTickets,
    error: userTicketsError,
    isFetching: isFetchingUserTickets,
  } = useQuery({
    queryKey: ["userTicketsData"],
    queryFn: getUserTickets,
  });

  const {
    status: receivingTicketsStatus,
    data: receivingTickets,
    error: receivingTicketsError,
    isFetching: isFetchingReceivingTickets,
  } = useQuery({
    queryKey: ["receivingTicketsData"],
    queryFn: getReceivingTickets,
  });

  const queryClient = useQueryClient();

  if (!isFetchingUserTickets && userTickets) {
    console.log("userTickets => ", userTickets);
  }

  // Multations
  const {
    mutate: createTicketTransferMultation,
    isPending: createTicketTransferMultationIsPending,
    error: createTicketTransferMultationError,
    isError: createTicketTransferMultationIsError,
  } = useMutation({
    mutationFn: createTicketTransfer,
    onSuccess: (data) => {
      console.log(
        "Create ticket transfer successful. Fetching updated cart data..."
      );
      queryClient.invalidateQueries({ queryKey: ["userTicketsData"] });
    },
  });

  const {
    mutate: acceptTicketsMultation,
    isPending: acceptTicketsMultationIsPending,
    error: acceptTicketsMultationError,
    isError: acceptTicketsMultationIsError,
  } = useMutation({
    mutationFn: acceptTicketTransfer,
    onSuccess: (data) => {
      console.log(
        "Accept ticket transfer successful. Fetching updated cart data..."
      );
      queryClient.invalidateQueries({ queryKey: ["userTicketsData"] });
    },
  });

  // Error handling
  if (userTicketsError) {
    console.log("userTicketsError => ", userTicketsError);
    const errorMessage = userTicketsError?.response?.data?.message;
    if (errorMessage === "Unauthorized") {
      navigate("/logout");
      return;
    } else {
      return <div>Error fetching orders</div>;
    }
  }

  // Loading message
  if (isFetchingUserTickets) {
    return <div>Loading...</div>;
  }

  // TODO: Add loading message when the fetching data is completed
  // TODO: Add error handling

  return (
    <>
      {!isFetchingReceivingTickets && receivingTickets && (
        <ReceivingTickets
          // isFetchingReceivingTickets={isFetchingReceivingTickets}
          receivingTickets={receivingTickets}
          acceptTicketsMultation={acceptTicketsMultation}
        />
      )}
      {isFetchingUserTickets ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.tickets}>
          <div className={styles.ticketsContainer}>
            <div className={styles.titleContainer}>
              <h2>Tickets</h2>
              <div className={styles.cardsContainer}>
                {userTickets.map((ticket) => {
                  console.log("ticket.performanceId => ", ticket.performanceId);

                  return (
                    <PerformanceCard
                      key={ticket.performanceId}
                      performanceData={ticket}
                      createTicketTransferMultation={
                        createTicketTransferMultation
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
