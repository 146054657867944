import styles from './ReceivingTickets.module.scss';

import PerformanceCard from '../PerformanceCard/PerformanceCard';

export default function ReceivingTickets({
  receivingTickets,
  acceptTicketsMultation,
}) {
  if (!receivingTickets) {
    return null;
  }

  return (
    <>
      {receivingTickets?.length > 0 && (
        <div className={styles.receivingTickets}>
          <div className={styles.receivingTicketsContainer}>
            <h2>Receiving tickets</h2>
            <div className={styles.cardsContainer}>
              {receivingTickets.map((performance) => (
                <PerformanceCard
                  key={performance.performanceId}
                  performanceData={performance}
                  acceptTicketsMultation={acceptTicketsMultation}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
