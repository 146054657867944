import styles from './TransferTickets.module.scss';
import { useState } from 'react';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';
import SelectTransferLine from './SelectTransferLine';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { useModal } from '../../../../context/useModal';
import SuccessfullCreatedTransferRequest from './SuccessfullCreatedTransferRequest';
// import { createTicketTransfer } from '../../../../utils/APIUtils';

export default function TransferTickets({
  ticketsList,
  showingLanguage,
  createTicketTransferMultation,
}) {
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [recipientEmail, setRecipientEmail] = useState('');
  const [error, setError] = useState(null);
  const { closeModal, openModal } = useModal();
  console.log('selectedTickets', selectedTickets);

  // Logic to check if all tickets are selected
  const checkIfAllSelected = () => {
    if (ticketsList.length !== selectedTickets.length) {
      console.log('ticketsList.length !== selectedTickets.length');
      return false;
    }

    // Sort both arrays
    const arr1 = ticketsList.map((ticket) => ticket.ticketId).sort(); // Make it an array of ticket ids
    const arr2 = selectedTickets.sort();

    return arr1.every((item, index) => item === arr2[index]);
  };
  // Derive selected all tickets from selectedTickets
  const allTicketsSelected = checkIfAllSelected();
  console.log('allTicketsSelected => ', allTicketsSelected);

  // Handle select all
  const handleSelectAll = () => {
    const allTicketIds = ticketsList.map((ticket) => ticket.ticketId);
    if (allTicketsSelected) {
      setSelectedTickets([]);
    } else {
      setSelectedTickets(allTicketIds);
    }
  };

  // Handle transfer tickets
  const handleTransferTicketsClick = async (e) => {
    e.preventDefault();
    console.log('transfer tickets clicked');
    try {
      if (selectedTickets.length === 0) {
        setError('Please select at least one ticket to transfer');
        return;
      }
      if (!recipientEmail) {
        setError('Please enter a recipient email');
        return;
      }
      await createTicketTransferMultation({
        transferTo: recipientEmail,
        ticketIds: selectedTickets,
      });
      closeModal();
      openModal(
        <SuccessfullCreatedTransferRequest receiverEmail={recipientEmail} />,
        'transferTickets'
      );
    } catch (error) {
      console.log('error on createTicketTransfer: ', error);
      // TODO: Handle error
    }
  };

  // Handle cancel transfering tickets
  const handleCancelClick = (e) => {
    e.preventDefault();
    console.log('cancel clicked');
    closeModal();
  };

  return (
    <form className={styles.transferTickets}>
      <div className={styles.closeBtnContainer}>
        <button onClick={closeModal}>X</button>
      </div>
      <div className={styles.transferTicketsContainer}>
        <section className={styles.transferTicketsHeader}>
          <h2>Transferring tickets</h2>
          <p>Select the tickets to transfer</p>
          <div className={styles.allTickets}>
            <CustomCheckbox
              onChangeChecked={handleSelectAll}
              checked={allTicketsSelected}
            />{' '}
            <span>All Tickets</span>
          </div>
        </section>
        <section className={styles.allTicketsContainer}>
          {ticketsList.map((ticket) => (
            <SelectTransferLine
              key={ticket.ticketId}
              ticketId={ticket.ticketId}
              status={ticket.status}
              typeTitle={ticket.typeTitle[showingLanguage]}
              sectionTitle={ticket.sectionTitle[showingLanguage]}
              selectedTickets={selectedTickets}
              onChangeChecked={setSelectedTickets}
            />
          ))}
        </section>
        <section className={styles.recipientContainer}>
          <h3>Recipient Details</h3>
          <input
            type="text"
            value={recipientEmail}
            onChange={(e) => {
              setRecipientEmail(e.target.value);
            }}
            placeholder="Email"
            className={styles.recipientInput}
          />
          {error && <p className={styles.recipientNote}>{error}</p>}
        </section>
        <section className={styles.btnsContainer}>
          <CustomButton
            title="Cancel"
            clickHandler={handleCancelClick}
            isPill={true}
            colorScheme="white"
          />
          <CustomButton
            title="Transfer Tickets"
            clickHandler={handleTransferTicketsClick}
            isPill={true}
          />
        </section>
      </div>
    </form>
  );
}
