export const transformOrderData = (order) => {
  console.log("order.purchaseDate => ", order.purchaseDate);

  // Get tomorrow's UTC midnight
  const nextUtcMidnight = new Date(order.purchaseDate);
  nextUtcMidnight.setUTCHours(24, 0, 0, 0);
  console.log("nextUtcMidnight => ", nextUtcMidnight);
  // const orderDateObject = new Date(order.purchaseDate);
  // console.log("orderDateObject => ", orderDateObject);

  const now = new Date();

  console.log("nextUtcMidnight - now => ", nextUtcMidnight - now);
  const gapBetweenNowAndNextUtcMidnight = nextUtcMidnight - now - 1000 * 60 * 5; // Remove 5 minutes to be safe

  // // Create a timestamp 5 minutes before midnight
  // const fiveMinutesBeforeMidnight = new Date(nextUtcMidnight);
  // fiveMinutesBeforeMidnight.setMinutes(
  //   fiveMinutesBeforeMidnight.getMinutes() - 5
  // );
  // console.log("fiveMinutesBeforeMidnight => ", fiveMinutesBeforeMidnight);

  // console.log(
  //   "isWithinFiveMinutes before midnight UTC => ",
  //   orderDateObject >= fiveMinutesBeforeMidnight
  // );
  return {
    orderId: order.transactionId,
    orderDate: new Date(order.purchaseDate).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }),
    // isWithinFiveMinutes: orderDateObject >= fiveMinutesBeforeMidnight,
    eventId: order.eventId,
    eventName: order.eventName,
    orderAmount: order.amountTotal,
    tickets: order.ticketsDetails,
    paymentIntentId: order.paymentIntentId,
    promoterStripeId: order.promoterStripeId,
    gapBetweenNowAndNextUtcMidnight: gapBetweenNowAndNextUtcMidnight,
  };
};

export const transformTicketData = (ticket) => {
  return {
    ticketId: ticket.ticketId,
    isRefundable: ticket.refundablePolice.isRefundable,
    refundableUntil: ticket.refundablePolice,
    performanceName: ticket.performanceName,
    performanceId: ticket.performanceId,
    sectionName: ticket.sectionName,
    typeName: ticket.typeName,
  };
};
