import styles from "./PerformanceCard.module.scss";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { useLanguage } from "../../../../context/useLanguage";
import {
  getShowingLanguage,
  getLanguageArray,
} from "../../../../utils/CommonUtils";
import { transformPerformanceCardData } from "./performanceCardDataTransform";
import { useState } from "react";
import { useModal } from "../../../../context/useModal";
import TransferTickets from "../TransferTickets/TransferTickets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { handleAddToCalendar } from "../TicketsUtils";
import PerformanceCartHeader from "./PerformanceCartHeader";
import TicketList from "./TicketList";
import AcceptTicketsList from "../ReceivingTickets/AcceptTicketsList";
import { useAuth } from "../../../../context/useAuth";

export default function PerformanceCard({
  performanceData,
  createTicketTransferMultation,
  acceptTicketsMultation,
}) {
  // Selected tickets handles the transfer tickets modal in case of receiving createTicketTransferMultation prop
  // in case of receiving acceptTicketsMultation it handles the tickets the user accepts
  const [selectedTickets, setSelectedTickest] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const { language } = useLanguage();
  const languageArray = getLanguageArray(performanceData.eventTitle);
  const showingLanguage = getShowingLanguage(languageArray, language);
  const { openModal, closeModal } = useModal();
  const { user } = useAuth();

  const transferTicketsBtnTitle = (
    <div className={styles.transferTicketsBtnTitle}>
      <span className={styles.icon}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </span>
      <span>Transfer Tickets</span>
    </div>
  );

  const {
    ticketCount,
    eventTitle,
    performanceTitle,
    startTime,
    endTime,
    listOfTickets,
    location,
  } = transformPerformanceCardData(performanceData, showingLanguage);

  // Perfomance data
  const performanceInfo = {
    eventTitle,
    performanceTitle,
    startTime,
    endTime,
  };

  const handleViewMobileTicketsClick = () => {
    console.log("view mobile tickets clicked");
  };

  // Opens modal to transfer tickets showing available tickets
  const handleTransferTicketsClick = () => {
    console.log("transfer tickets clicked");
    // Filter available tickets from listOfTickets - just the ones that are READY_TO_SCAN_PURCHASED
    const availableTickets = listOfTickets.filter(
      (ticket) => ticket.status === "READY_TO_SCAN_PURCHASED"
    );
    openModal(
      <TransferTickets
        ticketsList={availableTickets}
        showingLanguage={showingLanguage}
        onCloseModal={closeModal}
        createTicketTransferMultation={createTicketTransferMultation}
      />,
      "transferTickets"
    );
  };

  const handleShowDetailsToggle = () => setShowDetails(!showDetails); //handleShowDetailsToggle
  console.log(listOfTickets);

  const handleAddToCalendarClick = () => {
    const eventData = {
      title: eventTitle,
      description: performanceTitle,
      location: location,
      startTime: startTime,
      endTime: endTime,
    };

    // TODO: MAKE IT WORK ON MOBILE - I AM GETTING AN ERROR: INSECURE CONNECTION, WHEN TRYING TO SUBSCRIBE

    handleAddToCalendar(eventData);
  };

  return (
    <li className={styles.performanceCard}>
      <div className={styles.performanceCardContainer}>
        <PerformanceCartHeader
          handleShowDetailsToggle={handleShowDetailsToggle}
          showDetails={showDetails}
          eventTitle={eventTitle}
          performanceTitle={performanceTitle}
          ticketCount={ticketCount}
          startTime={startTime}
          handleAddToCalendarClick={handleAddToCalendarClick}
        />
        <section
          className={
            showDetails
              ? styles.performanceCardBody
              : `${styles.performanceCardBody} ${styles.hidden}`
          }
        >
          {/* If receiving createTicketTransferMultation show a list of sectionInfo, each one for a ticket */}
          {createTicketTransferMultation && (
            <TicketList
              listOfTickets={listOfTickets}
              showingLanguage={showingLanguage}
              selectedTickets={selectedTickets}
              setSelectedTickest={setSelectedTickest}
              performanceData={performanceInfo}
            />
          )}

          {/* If receiving acceptTicketsMultation show a list of AcceptTicketsSectionInfo, each one for a ticket */}
          {acceptTicketsMultation && (
            <AcceptTicketsList
              listOfTickets={listOfTickets}
              showingLanguage={showingLanguage}
              selectedTickets={selectedTickets}
              setSelectedTickets={setSelectedTickest}
            />
          )}
          <div className={styles.performanceCardFooter}>
            {/* If receiving createTicketTransferMultation show transfer tickets button and view mobile tickets */}
            {createTicketTransferMultation && (
              <>
                <CustomButton
                  title="View Mobile Tickets"
                  clickHandler={handleViewMobileTicketsClick}
                  variant="ticketPerformanceCard"
                />
                <CustomButton
                  title={transferTicketsBtnTitle}
                  clickHandler={handleTransferTicketsClick}
                  variant="ticketPerformanceCardWhite"
                />
              </>
            )}

            {/* If receiving acceptTicketsMultation show accept tickets button */}
            {acceptTicketsMultation && (
              <CustomButton
                title="Accept Tickets"
                clickHandler={() => acceptTicketsMultation(selectedTickets)}
                variant="ticketPerformanceCard"
              />
            )}
          </div>
        </section>
      </div>
    </li>
  );
}
