export const isMobileDevice = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

const formatLocation = (location) => {
  return [
    location.venue,
    location.addressLine1,
    location.addressLine2,
    location.city,
    location.province,
    location.country,
  ]
    .filter(Boolean) // Remove empty fields
    .join(', ');
};

export const generateCalendarEvent = ({
  title,
  description,
  location,
  startTime,
  endTime,
}) => {
  const formattedStartTime = formatDateToICS(startTime);
  const formattedEndTime = formatDateToICS(endTime);
  const locationString = formatLocation(location);
  return `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VEVENT
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${locationString}
DTSTART:${formattedStartTime}
DTEND:${formattedEndTime}
END:VEVENT
END:VCALENDAR`;
};
function formatDateToICS(date) {
  console.log('date is => ', date);
  const dateObj = new Date(date);
  const isoString = dateObj.toISOString(); // Get date in ISO format
  return isoString.replace(/[-:]/g, '').split('.')[0] + 'Z'; // Remove dashes, colons, and milliseconds
}

export const downloadCalendarEvent = (eventData) => {
  const icsData = generateCalendarEvent(eventData);
  const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'event.ics';
  link.click();
};

export const openWebcalLink = (eventData) => {
  const { title, description, location, startTime, endTime } = eventData;
  const locationString = formatLocation(location);
  const webcalURL = `webcal://add-to-calendar.com/?title=${encodeURIComponent(
    title
  )}&description=${encodeURIComponent(
    description
  )}&location=${encodeURIComponent(locationString)}&start=${encodeURIComponent(
    startTime
  )}&end=${encodeURIComponent(endTime)}`;
  window.location.href = webcalURL;
};

export const handleAddToCalendar = (eventData) => {
  const isMobileDevice = /Mobi|Android|iPhone/i.test(navigator.userAgent);

  if (isMobileDevice) {
    openWebcalLink(eventData);
  } else {
    downloadCalendarEvent(eventData);
  }
};
