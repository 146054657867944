import { transformStatus } from "./performanceCardDataTransform";
import styles from "./SectionInfo.module.scss";
import { useAuth } from "../../../../context/useAuth";
import { useModal } from "../../../../context/useModal";
import ViewDetailsModal from "../TransferTickets/ViewDetailsModal";

export default function SectionInfo({
  showingLanguage,
  ticket,
  performanceData,
}) {
  const { user } = useAuth();
  const { openModal, closeModal } = useModal();

  const { sectionTitle, typeTitle, status, ticketId, transferredAt } = ticket;
  const transformedStatus = transformStatus(ticket, user.patronUserId);
  const handleViewDetailsClick = () => {
    openModal(
      <ViewDetailsModal
        ticket={ticket}
        performanceData={performanceData}
        showingLanguage={showingLanguage}
      />,
      "transferTickets"
    );
  };

  const colorMatch = {
    READY_TO_SCAN_PURCHASED: "ready",
    READY_TO_SCAN_TRANSFERRED: "ready",
    PENDING_TRANSFER_RECEIVER: "pending",
    PENDING_TRANSFER_SENDER: "pending",
    PENDING_RESSEL: "pending",
    TRANSFERRED: "transferred",
  };
  const statusIndicatorClass = styles[colorMatch[status]];
  return (
    <li className={styles.sectionInfo}>
      <span>{sectionTitle[showingLanguage]}</span>
      <span>{typeTitle[showingLanguage]}</span>
      <span className={styles.status}>
        <div className={statusIndicatorClass}></div>
        {transformedStatus}
      </span>
      {ticket.ticketTransferDetails && (
        <button onClick={() => handleViewDetailsClick()}>View Details</button>
      )}
    </li>
  );
}
