import styles from './CustomCheckbox.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function CustomCheckbox({ onChangeChecked, checked, variant }) {
  console.log('checked', checked);

  return (
    <button
      type="button"
      className={`${styles.customCheckbox} ${variant ? styles[variant] : ''} ${
        checked ? styles.checked : ''
      }`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onChangeChecked();
      }}
    >
      {checked && (
        <FontAwesomeIcon icon={faCheck} className={styles.customCheckboxIcon} />
      )}
    </button>
  );
}
