import styles from './AcceptTicketsList.module.scss';
import AcceptTicketsSectionInfo from './AcceptTicketsSectionInfo';

export default function AcceptTicketsList({
  listOfTickets,
  showingLanguage,
  selectedTickets,
  setSelectedTickets,
}) {
  return (
    <>
      <div className={styles.bodyHeader}>
        <h3>Select</h3>
        <h3>Sec</h3>
        <h3>Seat/Type</h3>
      </div>
      <ul className={styles.bodyContent}>
        {listOfTickets.map((ticket, index) => (
          <AcceptTicketsSectionInfo
            key={`accept-ticket-${index}`}
            section={ticket.sectionTitle[showingLanguage]}
            typeTitle={ticket.typeTitle[showingLanguage]}
            status={ticket.status}
            ticketId={ticket.ticketId}
            selectedTickets={selectedTickets}
            setSelectedTickets={setSelectedTickets}
          />
        ))}
      </ul>
    </>
  );
}
