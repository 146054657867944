import styles from "./CartData.module.scss";
import { useCart } from "../../context/useCart";
import { useAuth } from "../../context/useAuth";
import {
  groupByPerformances,
  groupByEvents,
  getEventNames,
  getEventsTotal,
  assembleCheckoutObject,
} from "./CartDataUtils";
import { useNavigate } from "react-router-dom";
import {
  formatNumberToCurrency,
  getShowingLanguage,
} from "../../utils/CommonUtils";
import { useLanguage } from "../../context/useLanguage";
import CartCard from "./CartCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import { getItem } from "../../utils/LocalStorageUtils";
import { useState } from "react";
import CheckoutForm from "../Checkout/ChechoutForm";
import { deleteCart } from "../../utils/APIUtils";

export default function CartData() {
  const { cart, deleteCartMutate } = useCart();
  const { user } = useAuth();
  const { language } = useLanguage();
  const [placeOrder, setPlaceOrder] = useState(false);
  const [checkoutObj, setCheckoutObj] = useState({});
  const navigate = useNavigate();
  const ticketItems = cart.ticketItems;
  const groupedByEvents = groupByEvents(ticketItems);
  const eventTotals = getEventsTotal(groupedByEvents);
  const eventNames = getEventNames(groupedByEvents);
  const groupedItems = groupByPerformances(groupedByEvents);
  // const promoterStripeId = getPromoterStripeId(ticketItems); // REVIEW TO SEND THE RIGHT STRIPE ID
  console.log("groupedItems in CartData is => ", groupedItems);

  const handleCancelOrder = async () => {
    console.log("Cancel order");
    try {
      // Make an API call to cancel the order (all items in the cart become available and the cart is deleted)
      await deleteCartMutate();
      // Update the cart state and local storage
    } catch (err) {
      // Add error handling
      console.log(err);
    }
  };

  const handleCheckout = async (eventId) => {
    console.log("Checkout");
    const eventItems = groupedByEvents[eventId];
    const cartId = getItem("cartId");

    const showingLanguage = getShowingLanguage(
      eventItems[0].contentLanguage,
      language
    );
    console.log("user is => ", user);

    const patronObj = {
      id: user.patronUserId,
      email: user.email,
    };

    const checkoutObject = assembleCheckoutObject(
      eventItems,
      showingLanguage,
      cartId,
      patronObj
    );

    console.log("checkoutObject is => ", checkoutObject);

    setCheckoutObj(checkoutObject);
    setPlaceOrder(true);
  };

  // TODO: implement warnings for not enough items when we have a warning in the item
  return !placeOrder || Object.keys(checkoutObj).length === 0 ? (
    <div className={styles.cartData}>
      {Object.keys(groupedItems).map((eventId) => {
        return (
          <div key={eventId}>
            <h3>
              {eventNames[eventId][
                getShowingLanguage(
                  groupedByEvents[eventId][0].contentLanguage,
                  language
                )
              ] || ""}
            </h3>
            {Object.values(groupedItems[eventId]).map((itemsArray) => {
              return (
                <CartCard
                  performanceTicketData={itemsArray}
                  key={itemsArray[0].performanceId}
                />
              );
            })}
            <div className={styles.bottomContainer}>
              <div className={styles.totalContainer}>
                <p>
                  Subtotal for tickets:{" "}
                  {formatNumberToCurrency(eventTotals[eventId].ticketsAmount)}
                </p>
                <p>
                  Subtotal for fees:{" "}
                  {formatNumberToCurrency(eventTotals[eventId].feesAmount)}
                </p>
              </div>
              <div className={styles.btnsContainer}>
                <div className={styles.cancelBtnContainer}>
                  <CustomButton
                    title="Cancel Order"
                    clickHandler={handleCancelOrder}
                    variant={"white-outline"}
                  />
                </div>

                <div className={styles.checkoutBtnContainer}>
                  {user ? (
                    <CustomButton
                      title="Checkout"
                      clickHandler={() => handleCheckout(eventId)}
                      variant={"checkout"}
                    />
                  ) : (
                    <CustomButton
                      title="Login"
                      clickHandler={() => navigate("/login")}
                      variant={"checkout"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <CheckoutForm checkoutObj={checkoutObj} />
  );
}
