import styles from "./TicketList.module.scss";
import SectionInfo from "./SectionInfo";

export default function TicketList({
  listOfTickets,
  showingLanguage,
  performanceData,
}) {
  return (
    <>
      <div className={styles.bodyHeader}>
        <h3>Sec</h3>
        <h3>Seat/Type</h3>
        <h3>Status</h3>
      </div>
      <ul className={styles.bodyContent}>
        {listOfTickets.map((ticket) => (
          <SectionInfo
            key={ticket.ticketId}
            showingLanguage={showingLanguage}
            ticket={ticket}
            performanceData={performanceData}
          />
        ))}
      </ul>
    </>
  );
}
