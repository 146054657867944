import styles from "./OrderCard.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { formatNumberToCurrency } from "../../../utils/CommonUtils";
import TicketInfo from "./TicketInfo";
import { useLanguage } from "../../../context/useLanguage";
import {
  getLanguageArray,
  getShowingLanguage,
} from "../../../utils/CommonUtils";
import { transformOrderData } from "./ordersTransformation";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { requestReceipt } from "../../../utils/APIUtils";
import GeneralTimer from "../../../components/Timer/GeneralTimer";

// Card that shows order details
export default function OrderCard({ order }) {
  const [showMore, setShowMore] = useState(false);
  const [receiptError, setReceiptError] = useState(null);
  const [showCancelOrder, setShowCancelOrder] = useState(true);
  // TODO: Add error message to the UI, when we can not get the receipt

  console.log("order in OrderCard => ", order);

  const toggleShowMore = () => {
    setShowMore((prevValue) => !prevValue);
  };
  const transformedOrder = transformOrderData(order);
  const {
    orderId,
    orderDate,
    eventId,
    eventName,
    orderAmount,
    tickets,
    paymentIntentId,
    promoterStripeId,
    gapBetweenNowAndNextUtcMidnight,
  } = transformedOrder;

  console.log("Order date => ", orderDate);

  // Define showing languange
  // Get selected language on the website
  const { language } = useLanguage();
  // Get array of languages from performance name
  const languageArray = getLanguageArray(eventName);

  // Get showing language
  const showingLanguage = getShowingLanguage(languageArray, language);

  // Handles the Contact organizer button press
  const handleContactOrganizer = () => {
    console.log("contact organizer");
  };

  // Handles the receipt button press
  const handleReceipt = async () => {
    console.log("paymentIntentId: ", paymentIntentId);
    try {
      const receiptResponse = await requestReceipt(
        paymentIntentId,
        promoterStripeId
      );
      console.log("receiptResponse: ", receiptResponse);
      if (!receiptResponse.receipt) {
        setReceiptError("Receipt not found in response");
        return;
      }
      const receiptURL = receiptResponse.receipt;
      console.log("receiptURL: ", receiptURL);
      window.open(receiptURL, "_blank");
    } catch (error) {
      console.log("Error trying to request receipt: ", error);
      setReceiptError("Error trying to request receipt");
    }
  };

  const handleCancelOrder = () => {
    console.log("cancel order");
  };

  return (
    <li className={styles.orderCard}>
      <div className={styles.orderCardHeader}>
        <div className={styles.orderDate}>
          <span className={styles.description}>Order Placed</span>
          <span className={styles.data}>{orderDate}</span>
        </div>
        <div className={styles.orderTotal}>
          <span className={styles.description}>Order Total</span>
          <span className={styles.data}>
            {formatNumberToCurrency(orderAmount)}
          </span>
        </div>
        <div className={styles.orderNumber}>
          <span className={styles.description}>Order #</span>
          <span className={styles.data}>{orderId}</span>
        </div>
      </div>
      <span className={styles.event}>
        Event:&nbsp;
        <Link className={styles.eventLink} to={`/events/${eventId}`}>
          {eventName[showingLanguage]}
        </Link>
      </span>
      <div className={styles.btnsContainer}>
        <CustomButton
          clickHandler={handleContactOrganizer}
          title="Contact Organizer"
          variant="orderCard"
        />
        <CustomButton
          clickHandler={handleReceipt}
          title="Receipt"
          variant="orderCard"
        />
        {showCancelOrder && (
          <div className={styles.cancelOrderContainer}>
            <CustomButton
              clickHandler={handleCancelOrder}
              title="Cancel Order"
              variant="orderCard"
            />
            <GeneralTimer
              timeGap={gapBetweenNowAndNextUtcMidnight}
              onTimeExpired={() => setShowCancelOrder(false)}
            />
          </div>
        )}
      </div>
      <div className={styles.showMoreContainer}>
        <button className={styles.showMore} onClick={toggleShowMore}>
          {showMore ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </button>
      </div>
      {showMore && (
        <ul className={styles.ticketsInfoContainer}>
          {tickets.map((ticket) => (
            <TicketInfo
              ticketInfo={ticket}
              key={ticket.ticketId}
              showingLanguage={showingLanguage}
            />
          ))}
        </ul>
      )}
    </li>
  );
}
