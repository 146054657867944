export const getNumberOfTicketsPerPerformance = (cart, performanceId) => {
  if (!cart) return 0;
  const { ticketItems } = cart;
  if (!ticketItems) return 0;
  const cartItemsOfTheSamePerformance = ticketItems.filter(
    (item) => item.performanceId === performanceId
  );
  return cartItemsOfTheSamePerformance.reduce(
    (acc, item) => acc + item.selectedQuantity,
    0
  );
};
