import styles from './PerformanceCartHeader.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
import { formatDateToAmericanStyle } from '../../../../utils/CommonUtils';

export default function PerformanceCartHeader({
  handleShowDetailsToggle,
  showDetails,
  eventTitle,
  performanceTitle,
  ticketCount,
  startTime,
  handleAddToCalendarClick,
}) {
  return (
    <section className={styles.performanceCardHeader}>
      <div className={styles.iconContainer}>
        <button
          onClick={handleShowDetailsToggle}
          className={
            showDetails
              ? `${styles.toggleDetailsBtn} ${styles.active}`
              : styles.toggleDetailsBtn
          }
        >
          {showDetails ? (
            <FontAwesomeIcon icon={faArrowUp} />
          ) : (
            <FontAwesomeIcon icon={faArrowDown} />
          )}
        </button>
      </div>
      <div className={styles.data}>
        <div className={styles.title}>
          <div className={styles.description}>
            <h3>{eventTitle}</h3>
            <p>{performanceTitle}</p>
          </div>
        </div>
        <div className={styles.info}>
          <span>
            {ticketCount} {ticketCount > 1 ? 'Tickets' : 'Ticket'}
          </span>
          <button
            className={styles.calendarBtn}
            onClick={handleAddToCalendarClick}
          >
            <span>
              <FontAwesomeIcon icon={faCalendarDays} />
            </span>
            {formatDateToAmericanStyle(startTime, true)}
          </button>
        </div>
      </div>
    </section>
  );
}
