import styles from './SelectTickets.module.scss';
import { useState, useEffect } from 'react';
import { useModal } from '../../context/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import TicketSection from './TicketSection';
import { useQuery } from '@tanstack/react-query';
import { getTicketsByPerformanceId } from '../../utils/APIUtils';
import { useParams } from 'react-router-dom';
import { useCart } from '../../context/useCart';
import CustomButton from '../CustomButton/CustomButton';
import { getNumberOfTicketsPerPerformance } from './SelectTicketsUtils';

export default function SelectTickets({ performanceDetails, showingLanguage }) {
  const { closeModal } = useModal();
  const { addMutate, cart } = useCart();
  const performanceId = useParams().performanceId;
  const [ticketsSelected, setTicketsSelected] = useState({});
  const initialPerformanceTicketCount = getNumberOfTicketsPerPerformance(
    cart,
    performanceId
  );
  // Derive the number of tickets selected
  const numberOfTicketsSelected = Object.values(ticketsSelected).reduce(
    (acc, item) => acc + item.selectedQuantity,
    0
  );
  console.log('numberOfTicketsSelected are => ', numberOfTicketsSelected);
  // Calculate the maximum number of tickets that can be selected if the maxTicketsPerOrder is set
  // Otherwise, set it to 10000000
  // The real limit will be set by the availeble tickets
  const maxTicketsPerOrder =
    performanceDetails.ticketing.maxTicketsPerOrder || 10000000; // Basically setting no limit
  console.log('maxTicketsPerOrder are => ', maxTicketsPerOrder);

  const allowedTickets = maxTicketsPerOrder - initialPerformanceTicketCount;
  console.log('allowedTickets are => ', allowedTickets);

  const sections = performanceDetails.sections;

  console.log('ticketSelected are => ', ticketsSelected);

  const backgroundImageId =
    performanceDetails?.details?.mainImages?.[0] || null;

  const backgroundImageURL = backgroundImageId
    ? `${process.env.REACT_APP_IMG_BASE_URL}${backgroundImageId}/public`
    : null;

  const {
    data: sectionsDetails,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['ticketsByPerformanceId', performanceId],
    queryFn: async () => getTicketsByPerformanceId(performanceId),
    refetchOnMount: true,
  });
  console.log('sectionsDetails are => ', sectionsDetails);

  const handleValueChange = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name;

    if (!allowedTickets || value <= allowedTickets) {
      setTicketsSelected((prevSelection) => {
        const updatedType = {
          ...prevSelection[name],
          selectedQuantity: value,
        };

        return {
          ...prevSelection,
          [name]: { ...updatedType },
        };
      });
    }
  };

  const handleAddItemToCart = () => {
    const newCartItems = [];
    console.log('ticketsSelected are => ', ticketsSelected);

    Object.values(ticketsSelected).forEach((ticket) => {
      if (ticket.selectedQuantity > 0) {
        const strippedTicket = {
          ...ticket,
        };
        delete strippedTicket.price;
        delete strippedTicket.serviceFee;
        delete strippedTicket.sectionName;
        delete strippedTicket.typeName;
        strippedTicket.performanceId = performanceId;

        console.log('strippedTicket is => ', strippedTicket);

        newCartItems.push(strippedTicket);
      }
    });
    console.log('newCartItems are => ', newCartItems);

    addMutate(newCartItems);
    closeModal();
  };

  // Re-do when we have the data from the API and Context
  useEffect(() => {
    if (sectionsDetails) {
      const selected = {};
      sections.forEach((section) => {
        const sectionId = section.id;
        const sectionName = section.name;

        // const performanceId = section.performanceId;
        section.types.forEach((type) => {
          selected[type.id] = {
            typeId: type.id,
            typeName: type.name[showingLanguage],
            selectedQuantity: 0,
            sectionId: sectionId,
            sectionName: sectionName[showingLanguage],
            price: parseFloat(type.price),
            serviceFee: parseFloat(type.serviceFee),
          };
        });
        console.log('selected in useEffect is => ', selected);

        setTicketsSelected(selected);
      });
    }
  }, [sections, sectionsDetails]);

  console.log('backgroundImageURL: ', backgroundImageURL);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.selectTickets}>
      <div className={styles.selectTicketsContainer}>
        <div
          className={styles.header}
          style={
            backgroundImageURL
              ? { backgroundImage: `url(${backgroundImageURL})` } // Fixed syntax
              : undefined
          }
        >
          <div className={styles.btnContainer}>
            <button className={styles.closeBtn} onClick={closeModal}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className={styles.textContainer}>
            <h2>{performanceDetails.event.title[showingLanguage]}</h2>
            <h3>{performanceDetails.details.title[showingLanguage]}</h3>
          </div>
        </div>
        <div className={styles.selectContent}>
          <div className={styles.selectContentContainer}>
            <h2>Select your tickets</h2>
            {sectionsDetails.map((section) => (
              <TicketSection
                allowedTickets={allowedTickets}
                capacity={section.capacity}
                numberOfTicketsSelected={numberOfTicketsSelected}
                key={section.id}
                performanceId={performanceId}
                section={section}
                showingLanguage={showingLanguage}
                onValueChange={handleValueChange}
                ticketsSelected={ticketsSelected}
              />
            ))}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <CustomButton
            title="Add to cart"
            clickHandler={handleAddItemToCart}
          />
        </div>
      </div>
    </div>
  );
}
