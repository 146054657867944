import { parse } from "qs";
import { COUNTRY_ENUM, PROVINCE_ENUM } from "../constants/index";
import {
  faXTwitter,
  faInstagram,
  faFacebookF,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { lang } from "moment/moment";
import { removeItem } from "./LocalStorageUtils";
// Utility functions that are used to format data through out the code

/**
 * Capitalizes the first letter of every word in a string.
 * Assumption: input string only contains alphabets
 *
 * @param {string} str - The string to be capitalized.
 * @return {string} The string with the first letter of every word capitalized.
 */
const capitalizeStr = (str) => {
  str = str.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
    return letter.toUpperCase();
  });
  return str;
};

/**
 * Formats the given date into a string in the format 'YYYY-MM-DD'.
 *
 * @param {Date|string|number} date - The date to format. It can be a Date object, a string
 *                                   representation of the date, or a timestamp.
 * @return {string} The formatted date string.
 */
const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// const getFormDataObj = (obj) => {
//     let formData = new FormData();
//     for ( let key in obj ) {
//         let kValue = obj[key];
//         if(kValue == undefined)
//             continue;
//         else if(Array.isArray(kValue)){
//             if(kValue.length){
//                 for(let i = 0; i < kValue.length; i++){
//                     if(kValue[i] instanceof File || typeof(kValue[i]) == "string"){
//                         formData.append(key, kValue[i]);
//                     }
//                     else {
//                         for(let k in kValue[i]){
//                             let fdKey = key + "[" + i + "]" + "." + k;
//                             if(kValue[i][k]){
//                                 formData.set(fdKey, kValue[i][k]);
//                             }

//                         }

//                     }
//                 }
//             }
//         }
//         else if(typeof(obj[key]) == "string"){
//             formData.append(key, obj[key]);
//         }
//         else{
//             formData.append(key, obj[key]);
//         }

//     }
//     return formData;
// }

/**
 * Converts form that includes fields such as images, etc. to a FormData
 * which is used to post request to server.
 *
 * @param {Object} obj - The form data object to be converted.
 * @param {FormData} [formData=null] - The FormData object to append the
 * converted form data to. If not provided, a new FormData object will be
 * created.
 * @param {string} [parentKey=''] - The parent key to be used when appending
 * the converted form data to the FormData object.
 * @return {FormData} The FormData object containing the converted form data.
 */
const getFormDataObj = (obj, formData = null, parentKey = "") => {
  if (!formData) {
    formData = new FormData();
  }

  console.log("inform data", obj);

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey
        ? !isNaN(key)
          ? `${parentKey}[${key}]`
          : `${parentKey}.${key}`
        : key;

      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (typeof element === "object") {
            Object.keys(element).forEach((nestedKey) => {
              const nestedValue = element[nestedKey];
              const nestedFormKey = `${formKey}[${index}].${nestedKey}`;
              if (
                nestedValue instanceof File ||
                typeof nestedValue === "string"
              ) {
                formData.append(nestedFormKey, nestedValue);
              } else if (
                typeof nestedValue === "boolean" ||
                typeof nestedValue === "number"
              ) {
                formData.append(nestedFormKey, nestedValue.toString());
              } else if (
                typeof nestedValue === "object" &&
                nestedValue !== null
              ) {
                getFormDataObj(nestedValue, formData, nestedFormKey);
              }
            });
          } else {
            formData.append(`${formKey}[[${index}]]`, element);
          }
        });
      } else if (typeof value === "object" && value !== null) {
        if (value instanceof File || typeof value === "string") {
          formData.append(formKey, value);
        }
        getFormDataObj(value, formData, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
};

/**
 * Opens an external link in a popup window.
 *
 * @param {string} url - The URL of the external link.
 * @param {string} name - The name of the popup window.
 * @param {string} [params] - The parameters for the popup window.
 * @return {Window} The opened window object.
 */
const openExternalLinkInPopupWindow = (url, name, params) => {
  let win = window.open(
    url,
    name,
    `${params ? params : "width=600,height=600,top=85,left=500"}`
  );
  return win; // return window in case we need to play around with created window object
};

// Utility to deeply merge two objects
function mergeDeep(target, source) {
  if (typeof target == "object" && typeof source == "object") {
    for (const key in source) {
      if (typeof source[key] == "object" && key in target) {
        mergeDeep(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
}

const getAddressComponent = (addressComponents, type) => {
  const component = addressComponents.find((component) =>
    component.types.includes(type)
  );
  return component ? component.short_name : "";
};
export const formatAddress = (value, addressType) => {
  const rAddressLine1 = `${getAddressComponent(
    value.address_components,
    "street_number"
  )} ${getAddressComponent(value.address_components, "route")}`;
  const rAddressLine2 = getAddressComponent(
    value.address_components,
    "subpremise"
  );
  const rCity = getAddressComponent(value.address_components, "locality");
  const rCountry = getAddressComponent(value.address_components, "country");
  const rProvince = getAddressComponent(
    value.address_components,
    "administrative_area_level_1"
  );
  const rPostalCode = getAddressComponent(
    value.address_components,
    "postal_code"
  );
  const address = {
    addressLine1: rAddressLine1,
    addressLine2: rAddressLine2,
    city: rCity,
    country: COUNTRY_ENUM[rCountry] || rCountry,
    province: PROVINCE_ENUM[rProvince] || rProvince,
    postalCode: rPostalCode,
  };

  console.log("new address for ", addressType, " => ", address);

  return address;
};

function timeFormatter(time) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
  };
  const timeObj = new Date(time);
  return timeObj.toLocaleString("en-CA", options);
}

function formatDateToAmericanStyle(isoString, addWeekday) {
  const date = new Date(isoString);

  const optionsDate = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
  if (addWeekday) {
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    return `${weekday}. ${formattedDate}. ${formattedTime}`;
  } else {
    return `${formattedDate} ${formattedTime}`;
  }
}

// Retrieves the lowest price among all performances
const getStartingPrice = (performances) => {
  let price;
  performances.forEach((performance, index) => {
    const { startingPrice } = performance;

    if (index === 0 || (startingPrice && parseFloat(startingPrice) < price)) {
      price = parseFloat(startingPrice);
    }
  });
  return `$${price.toFixed(2)}` || "Free";
};

// Retrieves the earliest date among all performances
const getStartingDate = (performances) => {
  let time;
  performances.forEach((performance, index) => {
    const performanceDate = new Date(performance.startTime);

    if (index === 0) {
      time = performanceDate;
    } else if (performanceDate.getTime() < time.getTime()) {
      time = performanceDate;
    }
  });
  return time;
};

const loadImage = (src, fallback, callback) => {
  const img = new Image();
  img.src = src;
  img.onload = () => callback(src); // Image loaded successfully
  img.onerror = () => callback(fallback); // Image failed to load, use fallback
};

const getSocialMediaLink = ({ platform, accountId }) => {
  switch (platform) {
    case "instagram":
      return {
        link: `https://www.instagram.com/${accountId}`,
        icon: faInstagram,
      };
    case "facebook":
      return {
        link: `https://www.facebook.com/${accountId}`,
        icon: faFacebookF,
      };
    case "tiktok":
      return { link: `https://www.tiktok.com/@${accountId}`, icon: faTiktok };
    case "x":
      return { link: `https://x.com/${accountId}`, icon: faXTwitter };
    case "youtube":
      return { link: `https://www.youtube.com/${accountId}`, icon: faYoutube };
    default:
      return "#"; // Returns a placeholder link if the platform is unsupported
  }
};

const formatStartAndEndTimes = (startTime, endTime, lang) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const startDay = start.getDate();
  const startMonth = new Intl.DateTimeFormat(lang, { month: "short" }).format(
    start
  );
  const startYear = start.getFullYear();
  let startHour = start.getHours();
  const startPeriod = startHour >= 12 ? "PM" : "AM";
  startHour = startHour % 12 || 12;
  const startMinute = String(start.getMinutes()).padStart(2, "0");
  const endDay = end.getDate();
  const endMonth = new Intl.DateTimeFormat(lang, { month: "short" }).format(
    end
  );
  const endYear = end.getFullYear();
  let endHour = end.getHours();
  const endPeriod = startHour >= 12 ? "PM" : "AM";
  endHour = endHour % 12 || 12;
  const endMinute = String(end.getMinutes()).padStart(2, "0");

  return {
    start: `${startMonth} ${startDay}, ${startYear} ${startHour}:${startMinute} ${startPeriod}`,
    end: `${endMonth} ${endDay}, ${endYear} ${endHour}:${endMinute} ${endPeriod}`,
  };
};

const formatVenueAddress = (location) => {
  const { venue, city, province } = location;

  return `${venue}, ${city}, ${province}`;
};

const cleanUserObject = (user) => {
  delete user.additionalInfo;
  delete user.email;
  delete user.emailVerified;
  delete user.id;
};

const deleteNullValues = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
};

const deleteAddressesIfEmpty = (userDetails, isBillingEqualToShipping) => {
  let removeShippingAddress = false;
  let removeBillingAddress = false;
  if (
    userDetails.billingAddress.addressLine1 === "" ||
    userDetails.billingAddress.city === "" ||
    userDetails.billingAddress.zipCode === ""
  ) {
    removeBillingAddress = true;
    removeShippingAddress = isBillingEqualToShipping ? true : false;
  } else if (
    userDetails.shippingAddress.addressLine1 === "" ||
    userDetails.shippingAddress.city === "" ||
    userDetails.shippingAddress.zipCode === ""
  ) {
    removeShippingAddress = true;
  }

  if (removeBillingAddress) {
    delete userDetails.billingAddress;
  }

  if (removeShippingAddress) {
    delete userDetails.shippingAddress;
  }
};

// Handles click event for VIP button
const handleVIPClick = () => {
  console.log("VIP clicked");
  //TODO: ADD VIP WORKFLOW
};

// Receives the event or performance details the current picked language and returns the showing language
const getShowingLanguage = (languagesArray, language) => {
  if (languagesArray) {
    const showingLanguage = languagesArray.includes(language)
      ? language
      : languagesArray.contentLanguages[0];
    return showingLanguage;
  } else {
    return language;
  }
};

const formatNumberToCurrency = (number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // TODO: CHECK IF WE NEED TO MAKE IT DYNAMIC
  });
  return formatter.format(number);
};

//Returns an object from an array based on its id
const retrieveObjectWithId = (array, id) => {
  const objArray = array.filter((obj) => obj.id === id)[0];

  return objArray[0];
};

const clearCartLocalStorage = () => {
  removeItem("cartExpiration");
  removeItem("cartId");
};

// Gets the language array from an object that have text for multiple languages
const getLanguageArray = (object) => {
  return Object.keys(object).map((key) => key);
};
export {
  mergeDeep,
  capitalizeStr,
  formatDate,
  formatDateToAmericanStyle,
  getFormDataObj,
  openExternalLinkInPopupWindow,
  timeFormatter,
  getStartingPrice,
  getStartingDate,
  loadImage,
  getSocialMediaLink,
  formatStartAndEndTimes,
  formatVenueAddress,
  cleanUserObject,
  deleteNullValues,
  // checkIfAddressesNeedToBeRemoved,
  deleteAddressesIfEmpty,
  handleVIPClick,
  getShowingLanguage,
  formatNumberToCurrency,
  retrieveObjectWithId,
  clearCartLocalStorage,
  getLanguageArray,
};
