import styles from './AcceptTicketsSectionInfo.module.scss';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';

export default function AcceptTicketsSectionInfo({
  section,
  status,
  typeTitle,
  ticketId,
  selectedTickets,
  setSelectedTickets,
}) {
  console.log('ticketId => ', ticketId);

  const checked = selectedTickets.includes(ticketId);

  console.log('checked', checked);

  const handleCheckboxClick = () => {
    if (checked) {
      setSelectedTickets((prevSelectedTickets) => {
        return prevSelectedTickets.filter((id) => id !== ticketId);
      });
    } else {
      console.log('it is not checked');

      setSelectedTickets((prevSelectedTickets) => {
        const newValue = [...prevSelectedTickets, ticketId];
        console.log('newValue => ', newValue);

        return [...prevSelectedTickets, ticketId];
      });
    }
  };

  return (
    <li className={styles.acceptTicketsSectionInfo}>
      <CustomCheckbox
        onChangeChecked={handleCheckboxClick}
        checked={checked}
        variant="filterCheckbox"
      />
      <span>{section}</span> <span>{typeTitle}</span>
    </li>
  );
}
