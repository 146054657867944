import styles from './SelectTransferLine.module.scss';
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';

export default function SelectTransferLine({
  ticketId,
  sectionTitle,
  typeTitle,
  selectedTickets,
  onChangeChecked,
}) {
  const checked = selectedTickets.includes(ticketId);
  const handleChangeChecked = () => {
    console.log('ticketId', ticketId);
    console.log('selectedTickets', selectedTickets);
    console.log('checked', checked.current);

    if (checked) {
      onChangeChecked((prevSelectedTickets) => {
        return prevSelectedTickets.filter((id) => id !== ticketId);
      });
    } else {
      onChangeChecked((prevSelectedTickets) => {
        return [...prevSelectedTickets, ticketId];
      });
    }
  };
  return (
    <div className={styles.selectTransferLine}>
      <CustomCheckbox
        checked={checked}
        onChangeChecked={handleChangeChecked}
        variant="filterCheckbox"
      />
      <span>
        {sectionTitle} / {typeTitle}
      </span>
    </div>
  );
}
