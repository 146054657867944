import styles from './SuccessfullCreatedTransferRequest.module.scss';
import { useModal } from '../../../../context/useModal';

export default function SuccessfullCreatedTransferRequest({ receiverEmail }) {
  const { closeModal } = useModal();
  const now = Date.now();
  const dueDate = new Date(now + 24 * 59 * 60 * 1000).toLocaleString(); // Set to 24 hours minus 1 minute from now
  const infoList = [
    'Once the ticket has been confirmed by the recipient, you will be notified.',
    `If your guest or recipient has not accepted the tickets by ${dueDate} , tickets will be resent back to your order.`,
    'If there is a request for refund, the recipient will be notified of the deactivation of the ticket.',
  ];

  return (
    <div className={styles.successfullCreatedTransferRequest}>
      <div className={styles.closeContainer}>
        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </div>
      <div className={styles.container}>
        <h2>Transferring ticket</h2>
        <h3 className={styles.message}>
          Your request to transfer tickets has been sent to{' '}
          <strong>{receiverEmail}</strong>
        </h3>
        <ul>
          {infoList.map((info, index) => (
            <li key={`info-${index}`}>{info}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
