import styles from './ViewDetailsModal.module.scss';
import { useAuth } from '../../../../context/useAuth';
import { transformStatus } from '../PerformanceCard/performanceCardDataTransform';
import { formatDateToAmericanStyle } from '../../../../utils/CommonUtils';
import { useModal } from '../../../../context/useModal';

export default function ViewDetailsModal({
  ticket,
  performanceData,
  showingLanguage,
}) {
  const { user } = useAuth();
  const { closeModal } = useModal();
  const { status, ticketTransferDetails, sectionTitle, typeTitle } = ticket;
  console.log('ticket in ViewDetailsModal => ', ticket);
  console.log('performanceData in ViewDetailsModal => ', performanceData);
  const transformedStatus = transformStatus(ticket, user.patronUserId);
  const sectionLine = `${sectionTitle[showingLanguage]} / ${typeTitle[showingLanguage]}`;

  let classStatus;
  if (transformedStatus === 'Transferred') {
    classStatus = styles.transferred;
  } else if (transformedStatus === 'Ready to Scan') {
    classStatus = styles.readyToScan;
  } else if (transformedStatus === 'Pending Transfer') {
    classStatus = styles.pendingTransfer;
  }

  let statusMessage;
  if (status === 'PENDING_TRANSFER' && ticket.ticketTransferDetails) {
    // User created a ticket transfer request and is waiting for the recipient to accept
    statusMessage = `This ticket has been transferred to ${ticketTransferDetails.receiver} and is on pending to be approved by the recipient.`;
  } else if (
    status === 'READY_TO_SCAN' &&
    transformedStatus === 'Transferred'
  ) {
    // The user bought the ticket and transferred it to someone else
    statusMessage = `This ticket has been transferred to ${ticketTransferDetails.receiver}.`;
  } else {
    // The ticket was transferred to the user
    statusMessage = `This ticket has been transferred to you from ${ticketTransferDetails.sender}.`;
  }
  const StatusContainer = () => (
    <div className={`${styles.statusContainer} ${classStatus}`}>
      <div className={styles.statusTitle}>
        <span className={styles.icon}>ICON</span>
        <span className={styles.title}>Ticket Status</span>
      </div>
      <span className={styles.status}>{statusMessage}</span>
    </div>
  );
  const PerfomanceCard = () => (
    <div className={styles.performanceCard}>
      <span className={styles.event}>{performanceData.eventTitle}</span>
      <span className={styles.performance}>
        {performanceData.performanceTitle}
      </span>
      <span className={styles.date}>
        {formatDateToAmericanStyle(performanceData.startTime, true)}
      </span>
      <span className={styles.section}>{sectionLine}</span>
    </div>
  );
  const ButtonsContainer = () => {
    if (status === 'PENDING_TRANSFER' && ticket.ticketTransferDetails) {
      return (
        <div className={styles.buttonsContainer}>
          <button className={styles.cancelBtn}>Cancel Transffering</button>
        </div>
      );
    } else {
      return;
      <div className={styles.buttonsContainer}></div>;
    }
  };
  return (
    <div className={styles.viewDetailsModal}>
      <button className={styles.closeBtn} onClick={closeModal}>
        X
      </button>
      <StatusContainer />
      <PerfomanceCard />
      <ButtonsContainer />
    </div>
  );
}
