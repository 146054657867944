const orderTicketsFromList = (listOfTickets, showingLanguage) => {
  return listOfTickets.sort((a, b) => {
    if (a.sectionTitle[showingLanguage] < b.sectionTitle[showingLanguage]) {
      return -1;
    }
    if (a.sectionTitle[showingLanguage] > b.sectionTitle[showingLanguage]) {
      return 1;
    }
    return 0;
  });
};

export const transformPerformanceCardData = (data, showingLanguage) => {
  const listOfTicketsArray = data.listOfTickets || data.tickets;
  console.log(
    "listOfTicketsArray in transformPerformanceCardData is => ",
    listOfTicketsArray
  );

  const startTime = data.startTime || data.performanceStartTime;
  const endTime = data.endTime || data.performanceEndTime;

  return {
    performanceId: data.performanceId,
    ticketCount: data.ticketCount,
    eventTitle: data.eventTitle[showingLanguage],
    performanceTitle: data.performanceTitle[showingLanguage],
    startTime: startTime,
    endTime: endTime,
    location: data.location,
    listOfTickets: orderTicketsFromList(listOfTicketsArray, showingLanguage),
    performances: data.performances,
  };
};

export const transformTicketData = (data) => {
  return {
    ticketId: data.ticketId,
    ownedBy: data.ownedBy,
    sectionTitle: data.sectionTitle,
    transferredAt: data.transferredAt,
    transferredTo: data.transferredTo,
    status: data.status,
  };
};

//
export const transformStatus = (ticket, patronUserId) => {
  const status = ticket.status;

  switch (status) {
    case "READY_TO_SCAN_TRANSFERRED":
      return "Ready to Scan - Transferred";
    case "READY_TO_SCAN_PURCHASED":
      return "Ready to Scan - Purchased";

    case "TRANSFERRED":
      return "Transferred";
    case "PENDING_RESELL":
      return "Pending Resell";
    case "PENDING_TRANSFER_SENDER":
      return "Pending Transfer - Sender";
    case "PENDING_TRANSFER_RECEIVER":
      return "Pending Transfer - Receiver";
    default:
      return "Unknown";
  }
};
