import { useEffect, useState } from "react";
import styles from "./GeneralTimer.module.scss";

const GeneralTimer = ({ timeGap, onTimeExpired }) => {
  const [timeLeft, setTimeLeft] = useState(timeGap);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeExpired?.();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onTimeExpired]);

  const formatTime = (ms) => {
    if (ms < 0) return "00:00:00";

    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className={styles.generalTimer}>
      <span className={styles.time}>{formatTime(timeLeft)}</span>
    </div>
  );
};

export default GeneralTimer;
